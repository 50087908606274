import React from "react";
import ComingSoon from "react-coming-soon";
import background from "./images/background.jpeg";

function App() {
  return (
    <ComingSoon
      image={background}
      bgColor="#fff"
      textColor="#000"
      title="Sera Learning"
      subtitle="Coming Soon"
      // illustration="planning"
    />
  );
}

export default App;
